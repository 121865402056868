
@tailwind base;
@tailwind components;
@tailwind utilities;


/* src/index.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

